import { graphql, Link } from 'gatsby';
import React from 'react';

import { Helmet } from 'react-helmet';

import { css } from '@emotion/core';
import styled from '@emotion/styled';

import SiteNav from '../components/header/SiteNav';
import { PostCard } from '../components/PostCard';
import { Wrapper } from '../components/Wrapper';
import IndexLayout from '../layouts';
import { colors } from '../styles/colors';
import {
  inner,
  outer,
  PostFeed,
  SiteDescription,
  SiteQuote,
  SiteHeader,
  SiteHeaderContent,
  SiteMain,
  SiteTitle,
  SiteNavMain,
  SiteArchiveHeader,
  ResponsiveHeaderBackground,
  SiteHeaderBackground,
} from '../styles/shared';
import { PageContext } from '../templates/post';

import { Footer } from '../components/Footer';

import { NoImage, PostFull, PostFullHeader, PostFullTitle } from '../templates/post';

import config from '../website-config';

const _ = require('lodash');

interface TagsTemplateProps {
  data: {
    allMarkdownRemark: {
      totalCount: number;
      edges: Array<{
        node: PageContext;
      }>;
    };
  };
}

const PageTemplate = css`
  .site-main {
    margin-top: 64px;
    padding-bottom: 4vw;
    background: #fff;
  }

  //@media (prefers-color-scheme: dark) {
    .site-main {
      /* background: var(--darkmode); */
      background: ${colors.darkmode};
    }
  //}
`;

const BList = styled.ul`
  list-style-type:none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  width: 100%;

  li {
    display: inline-block;
    width: 100%;
  }

  @media (max-width: 500px) {

    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
    text-align: center;
  }

`;


const TagsPage: React.FC<TagsTemplateProps> = props => {
  const { edges } = props.data.allMarkdownRemark;

  let tags = _.uniq(
    _.flatten(
      edges.map(edge => {
        return _.castArray(_.get(edge, 'node.frontmatter.tags', []));
      }),
    ),
  );

  tags = _.map(
    tags, (tag) => {
      return {name: tag, count: 0};
    }
  );

  let cardImage = `${config.siteUrl}${props.data.header.childImageSharp.fixed.src}`
  let { width, height } = props.data.header.childImageSharp.fixed;

  return (
    <IndexLayout>
      <Helmet>
        <title>{`Topics - ${config.title}`}</title>
          <meta name="description" content={'Topics'} />
          <meta property="og:site_name" content={config.title} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`Topics - ${config.title}`} />
          <meta property="og:description" content={'Topics'} />
          <meta property="og:url" content={config.siteUrl + 'Topics'} />
          {config.facebook && <meta property="article:publisher" content={config.facebook} />}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`Topics - ${config.title}`} />
          <meta name="twitter:url" content={config.siteUrl + 'Topics'} />
          <meta property="twitter:description" content={'Topics'} />
          {config.twitter && (
            <meta
              name="twitter:site"
              content={`@${config.twitter.split('https://twitter.com/')[1]}`}
            />
          )}
          <meta name="og:image" content={cardImage} />
          <meta name="twitter:image" content={cardImage} />
          <meta property="og:image:width" content={width.toString()} />
          <meta property="og:image:height" content={height.toString()} />
      </Helmet>
      <Wrapper css={PageTemplate}>
        <header className="site-archive-header no-image" css={[SiteHeader, SiteArchiveHeader]}>
          <div css={[outer, SiteNavMain]}>
            <div css={inner}>
              <SiteNav isHome={false} isPost={true} post={{title: 'Topics'}} />
            </div>
          </div>
        </header>
        <main id="site-main" css={[SiteMain, outer]}>
          <div css={[inner]}>
            <SiteHeaderContent className="post-full-header">
              <SiteTitle style={{textAlign:'center'}} className="post-full-title">Topics</SiteTitle>
            </SiteHeaderContent>

            <div css={PostFeed} className="post-feed">
              <BList>
                {tags.map((tag) => (
                   <li key={tag.name}>

                      <Link to={`/topics/${_.kebabCase(tag.name)}`}>
                        {tag.name}
                      </Link>
                  </li>
                ))}
              </BList>
            </div>
          </div>
        </main>

        <Footer />
      </Wrapper>
    </IndexLayout>
  );
};


export const pageQuery = graphql`
  query {
    header: file(relativePath: { eq: "img/emptyrobot-background.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 1200, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
      allMarkdownRemark(
        sort: { fields: [frontmatter___date], order: ASC }
        filter: { frontmatter: { draft: { ne: true } } }
      ) {
        edges {
          node {
            excerpt
            timeToRead
            frontmatter {
              title
              tags
              date
              draft
              excerpt
            }
          }
        }
      }
    }
`;

export default TagsPage;
